<template>
  <div>
  <!-- ACADEMY -->
    <!-- Title Section -->
    <section class="title-section top">
      <div class="title-wrap">
        <!-- ACADEMY -->
        <h2>ACADEMY</h2>
        <div class="title-desc" v-html="$t('content.programs.ani.txt01')">
        </div>
      </div>
    </section>
    <!-- // Title Section -->

    <!-- 프로그램 혜택 -->
    <section class="inner-section type2">
      <div class="inner-lg">
        <StaggerTransition
          id="academy-benefits"
        >
          <ul class="icon-card-list line-type type2 ani-stagger">
            <!-- Blockchain Academy with UC Berkeley -->
            <li>
              <dl class="icon-card blockchain">
                <dt>
                  Blockchain Academy
                  <span class="with">with UC Berkeley</span>
                </dt>
                <dd v-html="$t('content.programs.ani.txt02')"></dd>
              </dl>
            </li>

            <!-- Fashion & Goods Academy with GS SHOP -->
            <li>
              <dl class="icon-card fashion">
                <dt>
                  Fashion & Goods Academy
                  <span class="with">with GS SHOP</span>
                </dt>
                <dd v-html="$t('content.programs.ani.txt03')"></dd>
              </dl>
            </li>

            <!-- F&B Academy with 장진우 아카데미 -->
            <li>
              <dl class="icon-card open-kitchen">
                <dt>
                  F&B Academy
                  <span class="with" v-html="$t('content.programs.ani.txt04')"></span>
                </dt>
                <dd v-html="$t('content.programs.ani.txt05')"></dd>
              </dl>
            </li>

            <!-- Onboarding Startup Academy with Google -->
            <li>
              <dl class="icon-card academy">
                <dt>
                  Onboarding Startup Academy
                  <span class="with">with Google</span>
                </dt>
                <dd v-html="$t('content.programs.ani.txt06')"></dd>
              </dl>
            </li>

            <!-- Contents Creator with YES24 / 플래디 -->
            <li>
              <dl class="icon-card digital-media-center">
                <dt>
                  Contents Creator
                  <span class="with" v-html="$t('content.programs.ani.txt07')"></span>
                </dt>
                <dd v-html="$t('content.programs.ani.txt08')"></dd>
              </dl>
            </li>

            <!-- Dreamin iOS Developer Academy -->
            <li>
              <dl class="icon-card developer">
                <dt>
                  Dreamin
                  <br />iOS Developer Academy
                </dt>
                <dd v-html="$t('content.programs.ani.txt09')"></dd>
              </dl>
            </li>
          </ul>
        </StaggerTransition>
      </div>
    </section>
    <!-- // 프로그램 혜택 -->

    <!-- 아카데미 현장사진 -->
    <section class="inner-section type3">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.ani.txt10')"></h3>
        </div>
        <div class="academy-field-wrap">
          <div class="image-area">
            <!-- 아카데미 현장사진 슬라이더 -->
            <BaseSlider
              :list="academyPhotoList"
              :pagination="true"
              :autoplay="false"
            >
              <template v-slot="{ item }">
                <img
                  :src="requireAssetsImage(item.image)"
                  :alt="item.name"
                />
              </template>
            </BaseSlider>
            <!-- // 아카데미 현장사진 슬라이더 -->
          </div>
          <span class="message" v-html="$t('content.programs.ani.txt11')"></span>
          <div class="btn-wrap">
            <ExternalLink
              to="https://dreamin.career/academy"
              class="btn-basic">
              {{$t('content.programs.ani.txt12')}}
            </ExternalLink>
          </div>
        </div>
      </div>
    </section>
    <!-- // 아카데미 현장사진 -->
  <!-- // ACADEMY -->

  <!-- INTERNSHIP -->
    <section class="grey-section type2">
      <div class="inner-lg">
        <!-- Title Section -->
        <section class="title-section">
          <div class="title-wrap">
            <!-- INTERNSHIP -->
            <h2>INTERNSHIP</h2>
            <div class="title-desc" v-html="$t('content.programs.ani.txt13')">
            </div>
          </div>
        </section>
        <!-- // Title Section -->
        <!-- 청년 TLO 스타트업 인턴십 -->
        <div class="card-list-wrap">
          <div class="title-wrap">
            <h3
              :class="{ kor: $i18n.locale === 'ko' }"
             v-html="$t('content.programs.ani.txt14')"></h3>
            <div class="title-desc" v-html="$t('content.programs.ani.txt15')">
            </div>
          </div>

          <StaggerTransition
            id="long-term-internship"
          >
            <ul class="card-list ani-stagger">
              <li
                v-for="(internship, index) in longTermInternshipList"
                :key="index"
                >
                <LogoCard
                  :name="internship.name"
                  :logo="requireAssetsImage(internship.image)"
                />
              </li>
            </ul>
          </StaggerTransition>
        </div>
      </div>
    </section>
    <!-- // 청년 TLO 스타트업 인턴십 -->

    <!-- 단기 인턴십-->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="card-list-wrap">
          <div class="title-wrap">
            <h3
              :class="{ kor: $i18n.locale === 'ko' }"
             v-html="$t('content.programs.ani.txt16')"></h3>
            <div class="title-desc" v-html="$t('content.programs.ani.txt17')">
            </div>
          </div>

          <StaggerTransition
            id="short-term-internship"
          >
            <ul class="internship-list ani-stagger">
              <li
                v-for="(internship, index) in shortTermInternshipList"
                :key="index"
                >
                <div class="internship">
                  <div class="internship-logo">
                    <img
                      :src="requireAssetsImage(internship.image)"
                      :alt="internship.name"
                    />
                  </div>
                  <dl>
                    <dt>{{ internship.desc1 }}</dt>
                    <dd>{{ internship.desc2 }}</dd>
                  </dl>
                </div>
              </li>
            </ul>
          </StaggerTransition>
        </div>
      </div>
    </section>
    <!-- // 단기 인턴십 -->
    <!-- 선발 프로세스 -->
    <section class="inner-section">
      <div class="inner-lg">
        <div class="title-wrap">
          <h3
            :class="{ kor: $i18n.locale === 'ko' }"
           v-html="$t('content.programs.ani.txt18')"></h3>
        </div>
        <ol class="process-list type2 internship">
          <!-- 참여 스타트업 모집 -->
          <li>
            <dl class="process" data-aos="fade-right">
              <dt>Step 01</dt>
              <dd v-html="$t('content.programs.ani.txt19')"></dd>
            </dl>
          </li>

          <!-- 인턴 모집 공고 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="200">
              <dt>Step 02</dt>
              <dd v-html="$t('content.programs.ani.txt20')"></dd>
            </dl>
          </li>

          <!-- 면접 심사 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="400">
              <dt>Step 03</dt>
              <dd v-html="$t('content.programs.ani.txt21')"></dd>
            </dl>
          </li>

          <!-- 드림플러스 매칭 지원 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="600">
              <dt>Step 04</dt>
              <dd v-html="$t('content.programs.ani.txt22')"></dd>
            </dl>
          </li>

          <!-- 최종 선발 -->
          <li>
            <dl class="process" data-aos="fade-right" data-aos-delay="800">
              <dt>STEP 05</dt>
              <dd v-html="$t('content.programs.ani.txt23')"></dd>
            </dl>
          </li>
        </ol>
      </div>
    </section>
    <!-- // 선발 프로세스 -->
  <!-- // INTERNSHIP -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import LogoCard from '@/components/card/LogoCard.vue';
import ExternalLink from '@/components/base/ExternalLink.vue';
import StaggerTransition from '@/components/transition/StaggerTransition.vue';
import BaseSlider from '@/components/slider/BaseSlider.vue';

/**
 * Programs > Academy & Internship
 */
export default {
  name: 'ProgramsAcademyAndInternship',
  mixins: [
    mixinHelperUtils,
  ],

  components: {
    LogoCard,
    ExternalLink,
    StaggerTransition,
    BaseSlider,
  },

  data() {
    return {
      /**
       * 장기 인턴십 리스트
       * @type {array}
       */
      longTermInternshipList: [
        {
          name: '과학기술일자리진흥원',
          image: 'programs/internship-1.png',
        },
        {
          name: '한양대학교',
          image: 'programs/internship-5.png',
        },
        {
          name: '연세대학교',
          image: 'programs/internship-3.png',
        },
        {
          name: '고려대학교',
          image: 'programs/internship-4.png',
        },
        {
          name: '이화여자대학교',
          image: 'programs/internship-2.png',
        },
        {
          name: '서울과학기술대학교',
          image: 'programs/internship-6.png',
        },
        {
          name: '덕성여자대학교',
          image: 'programs/internship-7.png',
        },
        {
          name: '충남대학교',
          image: 'programs/internship-8.png',
        },
      ],

      /**
       * 단기 인턴십 리스트
       * @type {array}
       */
      shortTermInternshipList: [
        {
          name: '연세대학교',
          image: 'programs/internship-logo-1.png',
          desc1: this.$t('content.programs.ani.txt24'),
          desc2: this.$t('content.programs.ani.txt25'),
        },
        {
          name: '이화여자대학교',
          image: 'programs/internship-logo-2.png',
          desc1: this.$t('content.programs.ani.txt26'),
          desc2: this.$t('content.programs.ani.txt27'),
        },
        {
          name: '한국외국어대학교',
          image: 'programs/internship-logo-3.png',
          desc1: this.$t('content.programs.ani.txt28'),
          desc2: this.$t('content.programs.ani.txt29'),
        },
      ],

      /**
       * 아카데미 현장사진 리스트
       * @type {array}
       */
      academyPhotoList: [
        {
          name: '아카데미 현장사진1',
          image: 'programs/academy-photo-1.jpg',
        },
        {
          name: '아카데미 현장사진2',
          image: 'programs/academy-photo-2.jpg',
        },
        {
          name: '아카데미 현장사진3',
          image: 'programs/academy-photo-3.jpg',
        },
        {
          name: '아카데미 현장사진4',
          image: 'programs/academy-photo-4.jpg',
        },
        {
          name: '아카데미 현장사진5',
          image: 'programs/academy-photo-5.jpg',
        },
        {
          name: '아카데미 현장사진6',
          image: 'programs/academy-photo-6.jpg',
        },
        {
          name: '아카데미 현장사진7',
          image: 'programs/academy-photo-7.jpg',
        },
      ],

    };
  },

};
</script>
